.header {
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: var(--table-title);
    border-bottom: 2px solid var(--black);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

.range {
    width: 500px;
    cursor: pointer;
}


.update-date {
    color: var(--white);
    position: -webkit-sticky;
    position: sticky;
    margin-right: 25px;
}

.update-date span {
    font-size: 20px;
}