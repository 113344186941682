.main {
    margin-top: 80px;
}

.main-price__border-right {
    border-right: 2px solid #000;
}

.main-price__border-left {
    border-left: 2px solid #000;
}

td {
    position: relative;
}

td a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}


.table {
    width: 100%;
    white-space: nowrap;
    border-collapse: separate;
    border-spacing: 0;
    -webkit-overflow-scrolling: touch;
}


th,
td {
    font-size: 17px;
    min-width: 110px;
}

.table-title {
    background-color: var(--table-title) !important;
    color: var(--white) !important;
    text-align: center;
}

.table-title a {
    text-decoration: none;
    color: var(--white) !important;
}
