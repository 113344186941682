.login-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-form {
    width: 50%;
}

.login-form-input:focus {
    border-color: var(--green);
    box-shadow: 0 0 0 0.25rem var(--green-focus);
}

.login-form-btn {
    margin-top: 20px;
    background-color: var(--green);
    border-color: var(--green);
}

.login-form-btn:hover {
    background-color: var(--green-hover);
    border-color: var(--green-hover);
}

.login-form-btn:active {
    background-color: var(--green) !important;
    border-color: var(--green) !important;
}

.login-form-btn:focus-visible {
    background-color: var(--green) !important;
    border-color: var(--green) !important;
    box-shadow: 0 0 0 0.25rem var(--green-focus) !important;
}

.login-form-btn:active:focus-visible {
    box-shadow: 0 0 0 0.25rem var(--green-focus) !important;
}