.green {
    background-color: var(--cell-green) !important;
    color: var(--cell-green-text) !important;
    font-weight: bolder;
}

.red {
    background-color: var(--cell-red) !important;
    color: var(--cell-red-text) !important;
    font-weight: bolder;
}