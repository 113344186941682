* {
  margin: 0;
  padding: 0;
  font-family: "Arial";
}

*::selection {
  background-color: var(--green);
  color: #fff;
}

*::-webkit-scrollbar {
  width: 0;
}


:root {
  --white: #fff;
  --black: #000;
  --green: #24a186;
  --green-hover: #2bc1a1;
  --green-focus: #24a18644;
  --table-title: #375e9c;
  --cell-green: #24a18631;
  --cell-red: #d84f2531;
  --cell-green-text: #005c46;
  --cell-red-text: #bd2c00;
}

@font-face {
  font-family: "Arial";
  src: url('fonts/ARIALN.TTF');
}