.th-title {
    position: relative;
}

.th-title p {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-price-image__td {
    width: 200px;
    overflow: hidden;
}

.main-price-image {
    width: 100%;
    position: relative;
    top: -50px;
}